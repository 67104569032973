
@font-face {
  font-family: 'circular std-500';
  src: url('../../assets/fonts/Circular/CircularStd-Medium-500.otf') format('opentype');
  /* Add other font properties if needed, like font-weight, font-style, etc. */
}

@font-face {
  font-family: 'circular std-450';
  src: url('../../assets/fonts/Circular/CircularStd-Book.otf') format('opentype');
  /* Add other font properties if needed, like font-weight, font-style, etc. */
}

@font-face {
  font-family: 'circular std-black';
  src: url('../../assets/fonts/Circular/CircularStd-Black.otf') format('opentype');
  /* Add other font properties if needed, like font-weight, font-style, etc. */
}

@font-face {
  font-family: 'circular std-bold';
  src: url('../../assets/fonts/Circular/CircularStd-Bold.otf') format('opentype');
  /* Add other font properties if needed, like font-weight, font-style, etc. */
}

@font-face {
  font-family: 'circular std-light';
  src: url('../../assets/fonts/Circular/CircularStd-Light.otf') format('opentype');
  /* Add other font properties if needed, like font-weight, font-style, etc. */
}

.font-circular-500 {
  font-family: 'circular std-500';
}

.font-circular-450 {
  font-family: 'circular std-450';
}

.font-circular-900 {
  font-family: 'circular std-black';
}
.font-circular-600 {
  font-family: 'circular std-bold';
}
.font-circular-light {
  font-family: 'circular std-light';
}


/* Hide scrollbar for Chrome, Safari, and Opera */
.cards-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for Firefox */
  .cards-container {
    scrollbar-width: none;
  }
  
  /* Hide scrollbar for Edge (Chromium-based) */
  .cards-container {
    -ms-overflow-style: none;
  }
  
  .slick-slider > button {
    display: none !important;
  }

  .slick-track {
    margin: 0 !important;
    overflow-y: hidden !important;
    height: 38rem !important;
  }

  .slick-list{
    width: 95vw !important;
    overflow-y: hidden !important;
  }
  .slick-slide{
    max-width: 25rem !important;
  }

  .container-footer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }