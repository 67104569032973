.weather-widget-main-container {
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "City Minmax"
    "Temperature Weather";
  align-items: center;

  @media (min-width: 640px) {
    grid-template-columns: auto 80px auto;
    grid-template-areas:
      "City Temperature Weather"
      "Minmax Temperature Weather";
  }

  @media (min-width: 1300px) {
    grid-template-columns: auto 90px 213px auto;
    grid-template-areas: "City Minmax Temperature Weather";
  }
}

.dashboard-weather-widget-main-container {
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "City Minmax"
    "Temperature Weather";
  align-items: center;

  @media (min-width: 640px) {
    grid-template-columns: auto 80px auto;
    grid-template-areas:
      "City Temperature Weather"
      "Minmax Temperature Weather";
  }
}

.main-city-area {
  grid-area: City;
}

.main-temperature-area {
  grid-area: Temperature;
}

.main-weather-area {
  grid-area: Weather;
}

.main-minmax-area {
  grid-area: Minmax;
}

.day-day-area {
  grid-area: Day;
  @media (min-width: 1300px) {
    justify-self: start;
    align-self: start;
  }
}

.day-weather-icon-area {
  grid-area: WeatherIcon;
  @media (min-width: 1300px) {
    justify-self: end;
    align-self: start;
  }
}

.day-min-area {
  grid-area: Min;
  @media (min-width: 1300px) {
    justify-self: center;
    align-self: end;
  }
}

.day-max-area {
  grid-area: Max;
  @media (min-width: 1300px) {
    justify-self: center;
    align-self: end;
  }
}

.weather-widget-day-container {
  grid-template-rows: 33px 40px 45px 45px;
  grid-template-areas:
    "Day"
    "WeatherIcon"
    "Min"
    "Max";
  @media (min-width: 1300px) {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "Day WeatherIcon"
      "Min Max";
  }
}

.dashboard-weather-widget-day-container {
  grid-template-rows: 33px 40px 45px 45px;
  grid-template-areas:
    "Day"
    "WeatherIcon"
    "Min"
    "Max";

  .day-day-area {
    @media (min-width: 1300px) {
      justify-self: center;
      align-self: start;
    }
  }

  .day-weather-icon-area {
    @media (min-width: 1300px) {
      justify-self: center;
      align-self: center;
    }
  }
}
