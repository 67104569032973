.otp-bg-image {
  background-image: url(../../../assets/login-background.png);
}

.otp-input {
  width: 100%;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 38px;
  font-size: 18px;
  line-height: 23px;
  color: #7a7a7a;
  border: 2px solid #f2f2f2;
  padding: 17px 19px 16px 70px;
  &[type="password"] {
    padding-right: 80px;
  }
  @media screen and (max-width: 1050px) {
    padding-left: 17px;
  }
}

.input-block {
  position: relative;
}
.otp-input-block {
  .otp-wrapper {
    display: flex;
    align-items: center;
    @media screen and (max-width: 620px) {
      display: block;
    }
    &-fields {
      display: flex;
    }
  }
  input {
    border-radius: 5px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    display: inline-block;
    &:not(:first-child) {
      margin-left: 10px;
    }
    @media screen and (min-width: 621px) {
      width: 30%;
    }
  }
  .resend {
    font-size: 16px;
    line-height: 25px;
    padding: 8px 25px;
    margin-left: 20px;
    @media screen and (max-width: 620px) {
      margin-left: 0px;
      margin-top: 20px;
    }
  }
  .error-text {
    margin-left: 0;
  }
}
