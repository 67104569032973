.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-container {
  position: relative;
  max-height: 95vh;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 11;
}

.modal-close {
  border: none;
  background-color: transparent;
  color: #888;
  font-size: 18px;
  cursor: pointer;
}
